<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 483.07 581.63"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M0,0V581.63H483.07V0ZM33.63,547.85V33.06h417V547.85Z"/><path d="M62.38,194.84H421V63.42H62.38ZM388.6,95.56v67.28h-293V95.56Z"/><rect x="86.54" y="359.71" width="54.83" height="32.54"/><rect x="341.61" y="359.55" width="54.74" height="32.68"/><rect x="86.75" y="454.55" width="54.62" height="33.23"/><rect x="341.68" y="454.66" width="54.56" height="33.27"/><rect x="214.45" y="359.59" width="54.64" height="32.75"/><rect x="214.48" y="454.64" width="54.55" height="33.29"/><rect x="86.63" y="263.15" width="54.7" height="32.75"/><rect x="341.7" y="263.24" width="54.73" height="32.78"/><rect x="214.44" y="263.21" width="54.61" height="32.73"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconCalculator',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
